:root {
  --page-width: 8.5in;
  --page-height: 11in;
  --content-width: 6.5in;
  --content-height: 9in;
  --margin-size: 1in;
  --primary-color: #1a1a1a;
  --background-color: #f5f5f5;
  --border-color: #e0e0e0;
  --hover-color: #f0f0f0;
  --shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* LLM Theme Colors */
  --claude-color: rgba(200, 227, 255, 0.9);
  --claude-hover: rgba(200, 227, 255, 0.3);
  --chatgpt-color: rgba(198, 246, 213, 0.9);
  --chatgpt-hover: rgba(198, 246, 213, 0.3);
  --llama-color: rgba(255, 213, 179, 0.9);
  --llama-hover: rgba(255, 213, 179, 0.3);
}

/* Editor Container */
.editor-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  overflow: hidden;
}

/* Toolbar */
.toolbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: white;
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--shadow);
  z-index: 10;
}

.toolbar-buttons {
  display: flex;
  gap: 8px;
}

.toolbar-button {
  padding: 8px;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.toolbar-button:hover {
  background: var(--hover-color);
}

/* Main Content */
.content-area {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

/* Document Area */
.document-area {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.document-area.split {
  width: 50%;
}

.document-area.full {
  width: 100%;
}

.document-page {
  width: var(--page-width);
  height: var(--page-height);
  background: white;
  box-shadow: var(--shadow);
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-margins {
  width: var(--content-width);
  height: var(--content-height);
  position: relative;
  box-sizing: border-box;
}

.page-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 11pt;
  line-height: 1.5;
  padding: 0;
  margin: 0;
}

.page-content p {
  margin: 0 0 1em 0;
}

.page-content * {
  max-width: 100%;
}

.page-number {
  position: absolute;
  bottom: 0.5in;
  left: 0;
  right: 0;
  text-align: center;
  color: #888;
  font-size: 10pt;
  user-select: none;
}

/* Chat Panel */
.chat-panel {
  width: 50%;
  border-left: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  background: white;
}

.llm-selector {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}

.llm-selector h3 {
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 500;
}

.llm-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.llm-button {
  padding: 8px 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.llm-button.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

/* Messages Area */
.messages-area {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.message {
  margin-bottom: 16px;
}

.user-message {
  background: var(--background-color);
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.message-timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 4px;
}

/* LLM Response Styles */
.llm-response {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
}

.llm-response .llm-name {
  font-weight: 500;
  margin-bottom: 4px;
}

.llm-response.claude {
  background-color: var(--claude-color);
}

.llm-response.chatgpt {
  background-color: var(--chatgpt-color);
}

.llm-response.llama {
  background-color: var(--llama-color);
}

/* Message Container Hover States */
.message:hover .llm-response {
  opacity: 0.6;
}

.message:hover .llm-response:hover {
  opacity: 1;
  transform: translateX(4px);
}

.message:not(:hover) .llm-response {
  opacity: 1;
}

/* Rephrase Highlight Styles */
.rephrase-highlight {
  transition: background-color 0.2s ease;
  border-radius: 2px;
  padding: 0 2px;
  cursor: pointer;
}

/* Default state - no visible highlight */
.rephrase-highlight {
  background-color: transparent;
}

/* LLM-specific highlight colors when hovered */
.rephrase-highlight.claude {
  background-color: var(--claude-hover);
}

.rephrase-highlight.chatgpt {
  background-color: var(--chatgpt-hover);
}

.rephrase-highlight.llama {
  background-color: var(--llama-hover);
}

/* Message Input */
.message-input {
  padding: 16px;
  border-top: 1px solid var(--border-color);
  position: relative;
}

.message-input textarea {
  width: 100%;
  padding: 12px;
  padding-right: 40px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  resize: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
}

.message-input textarea:focus {
  border-color: var(--primary-color);
}

.send-button {
  position: absolute;
  right: 24px;
  bottom: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.send-button:hover {
  color: var(--primary-color);
  opacity: 0.8;
}

/* Context Menu */
.context-menu {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: var(--shadow);
  z-index: 1000;
  min-width: 160px;
  max-width: 280px;
  padding: 4px;
}

.context-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  gap: 8px;
  border-radius: 2px;
  color: var(--primary-color);
}

.context-menu-item.context-menu-subitem {
  padding-left: 32px;
  font-size: 0.95em;
}

.context-menu-item:hover {
  background: var(--hover-color);
}

.context-menu-instructions {
  padding: 8px;
  border-top: 1px solid var(--border-color);
  margin-top: 4px;
}

.context-menu-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  resize: none;
  height: 80px;
  font-size: 13px;
  margin-bottom: 8px;
  background-color: white;
  font-family: inherit;
}

.context-menu-textarea:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
}

.context-menu-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.context-menu-button {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.context-menu-button.cancel {
  background: white;
  border: 1px solid var(--border-color);
  color: var(--primary-color);
}

.context-menu-button.cancel:hover {
  background: var(--hover-color);
}

.context-menu-button.send {
  background: #1976d2;
  color: white;
  border: none;
}

.context-menu-button.send:hover {
  background: #1565c0;
}

/* Loading Indicator */
.loading-indicator {
  margin-left: 4px;
  animation: ellipsis 1.4s infinite;
}

@keyframes ellipsis {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

/* Print Styles */
@media print {
  .toolbar,
  .chat-panel,
  .context-menu {
    display: none;
  }

  .document-area {
    padding: 0;
    overflow: visible;
    width: 100% !important;
  }

  .document-area.split {
    width: 100%;
  }

  .document-page {
    margin: 0;
    box-shadow: none;
    page-break-after: always;
  }

  .document-page:last-child {
    page-break-after: avoid;
  }

  .rephrase-highlight {
    background-color: transparent !important;
    padding: 0;
  }
}

.markdown-content > * + * {
  margin-top: 1rem;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.markdown-content pre {
  background-color: #f6f8fa;
  padding: 1rem;
  border-radius: 6px;
  overflow-x: auto;
}

.markdown-content code {
  background-color: #f6f8fa;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  font-family: monospace;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.markdown-content a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content blockquote {
  border-left: 4px solid #e1e4e8;
  padding-left: 1rem;
  color: #6a737d;
  margin: 1rem 0;
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #e1e4e8;
  padding: 0.5rem;
  text-align: left;
}

.markdown-content th {
  background-color: #f6f8fa;
}